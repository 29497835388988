import {useState,useEffect} from "react";   // 點擊按鈕並馬上改變文字的模組 React Hook
import styles from './ProductList.module.css'; //import css 模組 ProductList.module.css*
import {Link} from "react-router-dom";
import Title from "./Title";
import QuantityBtn from "./QuantityBtn";

export default function ProductList() {

   let [ProductList,setProductList]=useState([]);

   let [input,setInput]=useState('')
   


    useEffect(()=>{
      //1:沒有第二個參數:component 每次render都會觸發
      //2:Deoendency Array 是空array時:只會在第一次網頁render時會觸發
      //3:Deoendency Array 是變數時：第一次網頁render時+指定的變數改變會觸發
      fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
      .then(response=>response.json())
      .then(data=>setProductList(data))
      console.log(ProductList)
    },[]);//<== Dependency Array

    useEffect(()=>{
      if(input.length>4)
      {
        console.log("字串夠長")
      }
      else
      {
        console.log("字串太短")
      }
    },[input])
  return (
    <div>
      <input type="text" onChange={e=>setInput(e.target.value)}></input>
      <Title mainTitle="請選擇要購買的水果wow"></Title>
      
      <div className='productBorder'> {/* import css 檔案 index.css*/}
        {
        ProductList.map((product)=>{

                return(
                    <div className={styles.productBorder} key={product.id}>{/* import css 模組 ProductList.module.css css建議用這個*/}
                    {product.name}<br/>
                    {product.price}<br/>
                    <Link to={'/product/'+product.id}>
                    <img src={process.env.PUBLIC_URL+'/img/'+product.image} ></img><br/>
                    </Link>
                    
                    {product.description}<br/>
                    <QuantityBtn productInfo={product}/>
      
                    </div>
                )
            })
        }
      </div>
    </div>
  )
}

