import {BrowserRouter,Routes,Route,Link} from 'react-router-dom';
import ProductList from './ProductList';
import Checkout from './Checkout';
import ProductDetails from './ProductDetails';
import { CartContext } from './CartContext';
import { useState } from 'react';

function App() {

  const [cartItems,setCartItems]=useState([])
  return (
<BrowserRouter>

    <CartContext.Provider value={{cartItems,setCartItems}}>

    <Link to="/">首頁</Link>
    <Link to="/checkout">購物車</Link>
<Routes>
  <Route path="/" element={<ProductList/>}></Route>

  <Route path="/checkout" element={<Checkout/>}></Route>

  <Route path="/product" element={<ProductDetails/>}>
    <Route path=":id" element={<ProductDetails/>}></Route>
  </Route>

  <Route path='*' element={<p>找不到頁面</p>}></Route> {/* 如果進不到以上的頁面就會進來這裏  */}
</Routes>
</CartContext.Provider>
</BrowserRouter>
  );
}

export default App;
