import React from 'react'
import Title from "./Title";
import { Link } from 'react-router-dom';
import QuantityBtn from './QuantityBtn';
import { CartContext } from './CartContext';
import { useContext } from 'react';


export default function Checkout() {

    let {cartItems}=useContext(CartContext)

    let cartEmpty = cartItems.length<=0 ? true : false;
    let grandTotal= cartItems.reduce((total,product)=>{
        return total+=product.price*product.quantity
    },0)
    const freeShippingPrice=99
  return (
    //React Fragments 代替原來一開始的div，inspect就不會出現一開始的div了
    <>
        <Title mainTitle="你的購物車"></Title>

        {
            cartEmpty &&

              <div>
                購物車現在沒有商品<br></br>
                <Link to="/">去產品頁看看吧</Link>
              </div>  
        }
        {
            !cartEmpty &&
        <div>
            <React.Fragment id='cartSection'> {/*React Fragments 代替原來一開始的div，inspect就不會出現一開始的div了,這是全寫的因爲div裏面有id*/}
                {
                cartItems.map(product=>(
                    <div key={product.id} >
                        {product.name}<br></br> 
                        {product.price}元/件<br></br> 
                       <img src={process.env.PUBLIC_URL+'/img/'+product.image}></img> <br></br> 
                       {product.description} 
                       {product.price} 
                       購買數量{product.quantity} 
                       <QuantityBtn productInfo={product}/>
                    </div>
                ))
                
                }
            </React.Fragment>
            <div id='checkOutSection'>
                <div>全部貨品的總共</div>
                <div>{grandTotal}元</div>

                {
                    grandTotal >= freeShippingPrice?
                    <div>我們免費送貨</div>:
                    <div>滿${freeShippingPrice}免費送貨<br/>
                    還差${freeShippingPrice-grandTotal}</div>
                }
            </div>
        </div>
        }

   
    </>
  )
}
